/* Font sizes */

.fs-10{
	font-size: 10px !important;
}

.fs-12{
	font-size: 12px !important;
}

.fs-13{
	font-size: 13px !important;
}
.fs-14{
	font-size: 14px !important;
}
.fs-16{
	font-size: 16px !important;
}

.fs-18{
	font-size: 18px !important;
}
.fs-20{
	font-size: 20px !important;
}
.fs-21{
	font-size: 21px !important;
}
.fs-22{
	font-size: 22px !important;
}
.fs-24{
	font-size: 24px !important;
}
.fs-30{
	font-size: 30px !important;
}
.fs-40{
	font-size: 40px !important;
}

.fs-56{
	font-size: 56px !important;
}