/* ---------------------------------------------------
    USER PROFILE STYLE
----------------------------------------------------- */
.user-cover-photo {
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    img {
        top: 67%;
        transform: translate(0, -67%);
    }
}

.user-details {
    bottom: -25px !important;

    .user-profile-photo {
        width: 135px !important;
        height: 135px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }
    // .user-profile-photo {
    //     @media screen and (min-width: 575px) {
    //         width: 105px !important;
    //         height: 105px !important;
    //         border: 4px solid white !important;
    //         box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    //     }
    // }
}

.user-tab {
    margin-left: 1rem !important;
    border: 0 !important;

    button {
        border: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
    }

    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    .nav-item:hover {
        span {
            color: $primary !important;
        }
    }
}

.object-fit-cover{
    object-fit: cover !important;
}

.verify-button {
    width: 130px !important;
}

.verify-review-owner {
    background: #67AC5B !important;
}

// .product-wishlist-div {
//     background: url('../../../images/userprofile/wishListBg.png') !important;
//     background-size: 100% 100%!important;
//     background-repeat: no-repeat;
// }

.feature-image {
    width: 267px !important;
    height: 295px !important;
    object-fit: contain !important;
}

.verify-button {
    width: 130px !important;
}

.verify-author {
    background: #67AC5B !important;
}

.profile-image {
    width: 40px;
    height: 40px;
	object-fit: cover;
    border: 1px solid #d5d1d1;
}

.rounded-bottom-2 {
	border-bottom-left-radius: 0.375rem !important;
	border-bottom-right-radius: 0.375rem !important;
}

.z-1{
    z-index: 1 !important;
}

.slick-track {
	margin-left: 0 !important;
}

.product-wishlist-div {
    background: url('../../assets/images/wishlist/wishListBg.png') !important;
    background-size: 100% 100%!important;
    background-repeat: no-repeat;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2 !important;
}

.bg-secondary-web{
    background-color: #5C6376;
}

.text-secondary-web{
    color: #5C6376;
}

.verify-author {
    background: #67AC5B !important;
}

/* ---------------------------------------------------
    POST STYLE
----------------------------------------------------- */
.post-div {
    max-width: 950px !important;
}

.post-image {
    width: 100% !important;
    object-fit: cover !important;
    min-height: min(100%, 450px);
    max-height: 550px;
}

.mulitline-input {
    textarea:focus {
        border-color: $primary !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px $primary !important;
        color: var(--bs-body-color) !important;
        outline: 0 !important;
        min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)) !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        appearance: none !important;
        border-radius: var(--bs-border-radius) !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    }
}

.post-textarea:focus {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.meta-image {
    width: 560px !important;
    height: 320px !important;
    aspect-ratio: 16/9 !important;
    object-fit: scale-down !important;
}

/* ---------------------------------------------------
    PRIME REACT STYLE
----------------------------------------------------- */
.p-mention {
    width: 100% !important;
}

.p-mention .p-mention-panel {
    min-width: 300px !important;
    max-width: 300px !important;
}

.p-mention-panel {
    border: 1px solid whitesmoke !important;
    border-radius: 0.375rem !important;
    background: whitesmoke !important;
}

.p-inputtextarea {
    width: 100% !important;
    min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)) !important;
    padding: 0.375rem 0.75rem !important;
    display: block !important;
    appearance: none !important;
    background-clip: padding-box !important;
    border: 0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    background-color: transparent !important;
    
}

.dark-layout{
    .p-inputtextarea{
        color: white !important;
    }
}

.p-inputtextarea:focus {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.brand-mention-image {
    width: 40px;
    height: 40px;
	object-fit: cover;
}

.p-point4{
    padding: .4rem;
}

.p-point-3{
    padding: .3rem;
}

.p-0-point-1 {
    padding: 0.1rem !important;
}
.p-s-point-3 {
    padding-left: 0.3rem !important;
}
.p-e-point-3 {
    padding-right: 0.3rem !important;
}
.p-t-point-3 {
    padding-top: 0.3rem !important;
}
.p-b-point-3 {
    padding-bottom: 0.3rem !important;
}
.p-y-point-3 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}
.p-x-point-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
}
.pe-4-point-5 {
    padding-right: 2rem !important;
}
.m-y-point-3{
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important;
}
.m-s-point-3{
    margin-left: 0.3rem !important;
}
.m-e-point-3{
    margin-right: 0.3rem !important;
}
.m-t-point-3{
    margin-top: 0.3rem !important;
}

.m-b-point-3{
    margin-bottom: 0.3rem !important;
}
.m-b-point-1{
    margin-bottom: 0.1rem !important;
}
.metaHover:hover{
    background-color: #555;
    opacity: .3;
}

.z-pos{
    z-index: 1;
}

.z-neg{
    z-index: -1;
}

//slick slider

.slick-prev, .slick-next {
    display: none !important;
    z-index: 1 !important;
    top: 41.8% !important;
    width: 30px !important;
    height: 83.4% !important;
    transition: all .5s ease-in-out;
}

.slick-slider:hover {
    .slick-prev, .slick-next {
        display: block !important;
        transition: all .5s ease-in-out;
    }

    .slick-disabled {
        display: none !important;
    }
}

.slick-prev {
    left: 0 !important;
}

.slick-next {
    right: 0 !important;
}

.slick-prev:before {
    position: absolute !important;
    top: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    content: url('../../assets/images/slickSlider/slick-prev.svg') !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0.9 !important;
    background-image: linear-gradient(to right, rgba(61, 61, 61, 0.65), rgba(72, 72, 72, 0));
    padding: 0px 15px;
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}

.slick-next:before {
    position: absolute !important;
    top: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    content: url('../../assets/images//slickSlider//slick-next.svg') !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0.9 !important;
    background-image: linear-gradient(to left, rgba(61, 61, 61, 0.72), rgba(72, 72, 72, 0));
    padding: 0px 15px;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}

.slick-disabled {
    display: none !important;
}

//Dark color
.dark-color-bg{
    background-color: #221F2E !important;
    // background-color: #161D31 !important;
}
.dark-color-modal-header-bg{
    background-color: #221F2E !important;
}
.dark-color-text{
    color: #fff !important;
}

.dark-theme-dark-text{
    color: #221F2E !important;
}

//react select
.postType__placeholder{
    color: white !important;
}

.postType__single-value{
    color: white !important;
}
.lightTheme__input{
    color: black !important;
}

.react-datepicker-popper{
    z-index: 98 !important;
}
.dark-layout{
    .postType__input{
        color: white !important;
    }
    input[type="date"]{
        border: 1px solid rgb(240, 239, 239) !important;
    }

    .react-datepicker {
        background: #221F2E !important;
        // background: #283046 !important;
        color: white !important;
        // z-index: 2 !important;
    }

    .react-datepicker__header--custom {
        background: #221F2E  !important;
        // background: #283046 !important;
        color: white !important;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        color: white !important;
    }

    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
        color: grey !important;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        // background: rgb(230, 14, 7) !important;
        background: #1D5D90 !important;
    }

    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
        // background: #04e950 !important;
        background: #1D5D90 !important;
    }
    
}

.dark-layout .header-navbar-shadow {
    background: linear-gradient(180deg, rgba(34,31,46, 1) 44%, rgba(34,31,46, .80) 73%, rgba(34,31,46, 0)) !important;
    // background: linear-gradient(180deg, rgba(22, 29, 49, 1) 44%, rgba(22, 29, 49, .80) 73%, rgba(22, 29, 49, 0)) !important;
    // background: #221F2E !important;
}

//responsive

.user-cover-photo {
    @media screen and (max-width: 684px) {
        height: 150px !important;
    }
    
    @media screen and (min-width: 685px) {
        height: 170px !important;
    }
}

.react-datepicker__triangle{
    left: -10px !important;
}

.form-switch > .form-check-input:not(:checked) {
  /* Explicit Unchecked Styles */
  background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><circle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
}

.css-166bipr-Input{
    color: white !important;
}

.industry-swiper-slider {
    width: 80% !important;
    height: 48px !important;

    .swiper-slide {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: auto !important;
    }
}

.industry-label {
    display: flex !important;
    flex-direction: row-reverse !important;

    input {
        margin-right: 0 !important;
    }

    label {
        width: 100% !important;
        display: flex !important;
    }
}