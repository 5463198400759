/* appLoader */
.effect-1,
.effect-2,
.effect-3 {
  border-left: 3px solid #1390f8 !important;
}
/* activeColor */
a.active {
  /* background: linear-gradient(118deg, #1390f8, #1390f8) !important; */
}

.OonlyCapitalized {
  text-transform: capitalize !important;
}

.white-space {
  white-space: break-spaces !important;
}
.WQNxq {
  min-height: 160px !important;
}
.customWidth {
  width: 375px !important;
}
.string-content {
  padding: 6px;
  height: 70px;
  width: 360px;
  overflow: scroll;
}
