/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import './bootstrap-extended/variables';

// Figtree Font
@import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');

body{
    font-family: 'Figtree', sans-serif !important;
}

.parent{ margin: auto;}
.myHeight{ height: 100vh; display: flex; align-items: center; text-align: center;}
.myCapitalized { text-transform: capitalize}
.bg-custom{
	background-color: pink !important;
}

.login-bg{
   background-image: url("../../assets/images/pages/bg-auth-addignis.jpg");
//    height: 100vh;
//    width: 100%;
   background-size: cover;
   background-repeat: no-repeat;
   min-height: 100%;
   background-position:50%
}

.login-input-bg{
    background-color: #221f2e !important;
}

.login-input-border{
    border: 1px solid #495057 !important;
}

// .auth-login-form {
//     input[type='password']::placeholder {
//         font-family: FuturaBook !important;
//     }
//     input[type='password'] {
//         font-family: sans-serif !important;
//     }
// }

.captcha-width > div > div {
   // text-align: center !important;
   margin-right: auto !important;
   margin-left: auto !important;
}

.verification-input {
   font-size: 18px !important;
   font-weight: 400 !important;
   border: 1px solid $primary !important;
   width: 40px !important;
   height: 35px !important;
   padding: 0 !important;
}

.verification-input[value=""] {
   border-color: #C4C4C4 !important;
}

.verification-input::-webkit-inner-spin-button {
    display: none !important;
}

.verification-input-div {
   width: 350px !important;
}

//card
.brand-card, .product-card, .user-card, .group-card {
    width: 150px !important;

    img {
        width: 148px !important;
        height: 140px !important;
        aspect-ratio: auto 148 / 140 !important;
        border-top-left-radius: 0.375rem !important;
        border-top-right-radius: 0.375rem !important;
    }
}
.brand-follow-card:hover, .brand-card:hover, .product-card:hover, .user-card:hover, .group-card:hover {
   //  border: 1px solid red !important;
    border: 1px solid $primary !important;
    transition: all 0.3s ease-in-out 0s !important;
    // box-shadow: rgb(0, 0, 0, 0.07) -2px -2px 8px, rgb(0, 0, 0, 0.07) -3px -3px 6px 0px, rgb(0, 0, 0, 0.07) 4px 0px 4px, rgb(0, 0, 0, 0.07) 0 0 6px, rgb(0, 0, 0, 0.07) 0px 5px 4px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05) !important;
}

// .hover-blur:hover{
//     background-color: #555;
//     opacity: .3;
// }

.hover-blur {
inset: 0px !important;
    opacity: 0.6 !important;
   //  backdrop-filter: blur(2px) !important;
    background: #555 !important;
} 
.bg-opacity-20 {
  --bs-bg-opacity: 0.2 !important;
}

.ql-editor {
  overflow-y: scroll !important;
  resize: vertical !important;
  min-height: 18em !important;
}

.clearProfileWidth{
   width: 150px !important;
}

//post image blur
.image_blur{
   background-color: #555 !important;
   opacity: .1;
}
.image_unblur{
   background-color: transparent !important;
}

.top_left_position{
   top: 35% !important;
   left: 40% !important;
   z-index: 1;
}

//close button
.btn-close{
   background-image: url("../../assets/images/cross/cross.svg") !important;
   background-repeat: no-repeat;
   background-size: 30px 30px !important;
   background-color: transparent !important;
   padding: 1.2rem !important;
   box-shadow: none !important;
   transform: translate(0, 0) !important;
   opacity: .8 !important;

}

.btn-close:hover{
   transform: translate(0, 0) !important;
   opacity: 1 !important;

}

.m-end-custom{
   margin-right: 5px !important;
}

//show scroll 
.modal-open {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding-right: 0px !important;
}

.dot {
    width: 3px !important;
    height: 2.5px !important;
    background-color: #5d5d5d !important;
}

.list-style-type-none {
    list-style-type: none !important;
}

//dropdown (react select)
.css-164yvry-control, .css-b62m3t-container {
    background: #221F2E !important;
//  background: #283046 !important;
}

.css-1nmdiq5-menu {
    color: rgb(0, 0, 0) !important;
}

.css-12a83d4-MultiValueRemove{
    color: #000 !important;
}


.dark-layout{
   .css-13r3304-control {
      background: transparent !important;
      border-color: #495057 !important;
   }

   .css-164yvry-control, .css-13r3304-control:hover, .css-164yvry-control:hover {
      border: 1px solid white !important;
      box-shadow: 0px 0px 2px white !important;
   }

   .css-164yvry-control, .css-b62m3t-container {
      background: #221F2E !important;
    //   background: #283046 !important;
   }

   .css-1dimb5e-singleValue {
      color: white !important;
   }

   .css-1nmdiq5-menu {
        z-index: 99 !important;
        background: transparent !important;
   }
   
}

// react datatable
// .react-dataTable{
//     transform:rotateX(180deg) !important;
// }
.react-dataTable::-webkit-scrollbar {
    position: absolute !important;
    top: 0 !important;
	background-color: transparent !important;
    height: 3px !important;
}

.react-dataTable::-webkit-scrollbar-thumb
{
    border-radius: 100px;
    -webkit-box-shadow: inset 0 0 6px rgba(158, 128, 128, 0.3);
    background-color: #e0dddd;
}

.react-dataTable::-webkit-scrollbar-thumb:hover
{
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 6px rgba(155, 134, 134, 0.3);
    background-color: #fff;
}
// .sc-dmctIk{
//     background-color: #38A019 !important;
// }
.ejbGxI{
    font-size: 13px !important;
}
.kCvbmm{
    font-size: 14px !important;
}
// ------------------------------------

//sun/moon, dark/light theme
.moon {
    color: #8643ff !important;
    fill: white !important;
}

.sun:hover {
    fill: white !important;
}

.moon:hover {
    color: #8643ff !important;
    fill: #221F2E !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.four-zero-four {
    @media screen and (max-width: 991px) {
        background: url('../../assets/images/error/error.svg') !important;
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
    }
}

.misc-wrapper .misc-inner{
   max-width: 80% !important;
}

//nav bar header
// .dark-layout{
//    .header-navbar-shadow{
//       background: #221F2E !important;
//    }
// }

.dark-layout{
   .add-new-industry{
      background: #221F2E !important;
   }
}

/* ---------------------------------------------------
    BRAND PROFILE STYLE
----------------------------------------------------- */
.brand-details {
    bottom: -25px !important;
}

.brand-cover-photo {
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .cover-image {
        height: 100% !important;
        object-fit: cover !important;
    }

    img, .reactEasyCrop_Container {
        border-top-left-radius: 1.9rem !important;
        border-top-right-radius: 1.9rem !important;
    }
}

.brand-review-user {
    width: 90px !important;
    height: 90px !important;
    object-fit: cover !important;
}

.brand-confidence-number {
    .animated-container {
        div:first-child {
            div {
                text-align: end !important;
            }
        }

        div:nth-child(4) {
            div {
                text-align: end !important;
            }
        }
    }
}

.brand-tab {
    margin-left: 12rem !important;
    border: 0 !important;

    button {
        border: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
    }

    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    .nav-item:hover {
        span {
            color: $primary !important;
        }
    }
}

/* ---------------------------------------------------
    INDUSTRY STYLE
----------------------------------------------------- */
.selected-industry {
    background-color: #8643ff !important;
    border-color: #8643ff !important;
    color: white !important;
}

.not-selected-industry {
    background-color: #e7e7e7 !important;
    border-color: #8643ff !important;
    color: #8643ff !important;
}

.subindustryselected-list {
    margin-bottom: 20px !important;
    grid-gap: 5px !important;
    gap: 5px !important;
    min-height: 70px !important;
    max-height: 130px !important;
    overflow-y: overlay !important;
}

.vh-60 {
    height: 60vh !important;
}

.top-80 {
    top: 80% !important;
}

.start-85 {
    left: 85% !important;
}

/* ---------------------------------------------------
    INFINITY SCROLL
----------------------------------------------------- */

.infinite-scroll-component{
    overflow: hidden !important;
}

/* ---------------------------------------------------
    PRODUCT STYLE
----------------------------------------------------- */
.product-creation-image {
    width: 68px !important;
    height: 68px !important;
    object-fit: cover !important;
}

.text-product-tab{
    color: #15053e !important;
}

.product-tab {
    border-radius: 0.375rem !important;
    button {
        background: $primary !important;
        border: none !important;
    }

    button:hover {
        span {
            color: white !important;
        }
    }

    .active {
        span {
            color: white !important;
        }
    }

    li:first-of-type {
        margin-left: 1rem !important;
    }
}


.dark-layout {
    .product-tab {
        border-radius: 0.375rem !important;
        button {
            background: $primary !important;
            border: none !important;
        }

        button:hover {
            span {
                color: #15053e !important;
            }
        }

        .active {
            span {
                color: #15053e !important;
            }
        }

        li:first-of-type {
            margin-left: 1rem !important;
        }
    }
}

.product-image {
    width: 345px !important;
    height: 345px !important;
}

.product-preview-image {
    width: 45px !important;
    height: 45px !important;
    object-fit: contain !important;
}

.product-review-number {
    .animated-container {
        div:first-child {
            div {
                text-align: end !important;
            }
        }

        div:nth-child(4) {
            div {
                text-align: end !important;
            }
        }
    }
}

/* ---------------------------------------------------
    SCROLLBAR STYLE
----------------------------------------------------- */
/* Browser statndard style reset here. */
* {
	/* width */
	&::-webkit-scrollbar {
		width: 5px !important;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: none !important;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888 !important;
		border-radius: 50px !important;
		visibility: visible !important;
	}
}
/* ----------------------------------------------------- */

.bg-opacity-70 {
  --bs-bg-opacity: 0.7 !important;
}

.bg-opacity-70-background-color {
    background-color:#666 !important;
  --bs-bg-opacity: 0.7 !important;
}

.review-title::first-letter {
    text-transform: uppercase !important;
}

// influence
.brand-follow-image, .wishlist-image {
    width: 52px !important;
    height: 52px !important;
}

.followers-tab {
    border: none !important;
    border-bottom: 1px solid !important;
    border-color: #3b4253  !important;

    button {
        border: none !important;
        border-bottom: 1px solid !important;
        padding-left: 0 !important;
        border-color: transparent !important;
    }

    button.active {
        border-color: #3b4253  !important;
        border-radius: 0 !important;
    }
}

.bg-badge{
    background-color: #858EA0 !important;
}

.user-follower-list, .user-following-list, .user-follower-brand-list, .user-follower-product-wishlist {
    max-height: 300px !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}

.user-follow-tab-div {
    .tab-content {
        width: 100% !important;
        flex-grow: 1 !important;
    }
}

// Product

.brand-and-product-percentage-red {
    color: #D3212C !important;
}

.brand-and-product-percentage-orange {
    color: #EF6C00 !important;
}

.brand-and-product-percentage-green {
    color: #38A019 !important;
}
// ------------------------------------

.dark-layout{
    .tooltip > div.tooltip-inner {
        background-color: #fff !important;
        color: black !important;
    }

    .tooltip > .tooltip-arrow::before {
        border-top-color: #fff !important;
    }

    .primary-text {
        color: #fff !important;
    }
}

.primary-text {
    color: #000 !important;
}

.primary-text:hover {
    color: $primary !important;
}

// ------footer--------
.site-logo {
    width: 200px !important;
}

.footer-logo {
    width: 200px !important;
}
//----------------------------

/* ---------------------------------------------------
    REACT MENTION STYLE
----------------------------------------------------- */
.react-mention-test {
    div:nth-child(2) {
        // border: 1px solid whitesmoke !important;
        border-radius: 0.375rem !important;
        background: white !important;
        min-width: 300px !important;
        max-width: 300px !important;
        max-height: 220px !important;
        overflow-y: scroll !important;
    }
}

.react-mention-test__suggestions{
    border: 1px solid whitesmoke !important;
    overflow-x: hidden !important;
}

.dark-layout{
    .react-mention-test {
        div:nth-child(2) {
            background: #221F2E !important;
        }
    }
    .react-mention-test__input{
        border: 0 !important;
        color: white;
    }
}

.react-mention-testinput {
    position: relative !important;
    width: 100% !important;
    resize: auto !important;
    min-height: 8.25em !important;
    padding: 0.375rem 0.75rem !important;
    display: block !important;
    appearance: none !important;
    background: transparent !important;
    background-clip: padding-box !important;
    border: 0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.react-mention-testinput:focus {
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.react-mention-test__highlighter {
    min-height: 8.25em !important;
    position: absolute !important;
}
.brand-mention-image {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover !important;
}

.react-mention-test__control{
    height: 100px !important;
}
.react-mention-test__highlighter{
    border: 0 !important;
}
.react-mention-test__input{
    border: 0 !important;
}
//-----------------------------

// width of sidebar
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu{
    width: 282px !important;
}

// date range picker
.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    color: white !important;
    background-color: $primary !important;
    border-radius: 50% !important;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
    border-color: $primary !important;
    border-radius: 50% !important;
    border-width: 1px !important;
    border-style: solid !important;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: none !important;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    width: 100% !important;
    margin-left: 0 !important;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
    width: 100% !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.react-datepicker__input-time-container {
    margin: 5px 0 10px 0px !important;
}

.react-datepicker__navigation {
    height: 59px !important;
}

.react-datepicker__close-icon::after {
    background-image: url("../../assets/images/cross/cross.svg") !important;
    background-repeat: no-repeat;
    background-size: 20px 20px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    content: "" !important;
    width: 20px !important;
    height: 20px !important;
}

//analytics followers/followings tab

.analytics-followers {
    border: none !important;
    border-bottom: 1px solid #dae1e7 !important;

    button {
        border: none !important;
        border-bottom: 1px solid !important;
        padding-left: 0 !important;
        border-color: transparent !important;
    }

    button.active {
        border-color: $primary !important;
    }
}

.dark-layout{
    .analytics-followers{
        border-bottom: 1px solid #3b4253 !important;
    }
}

.white-break-spaces {
  white-space: break-spaces !important;
}