/* ---------------------------------------------------
    SKELETON STYLE
----------------------------------------------------- */

.bg-skeleton {
    background-color: #EBEBEB !important;
}

.input-skeleton {
	height: 38px !important;
}

.radio-skeleton {
	width: 18px !important;
	height: 17px !important;
}

.div-skeleton {
	height: 75px !important;
}

.p-skeleton {
	width: 100px !important;
	height: 20px !important;
}

.h1-skeleton {
	width: 60px !important;
	height: 50px !important;
}

.span-skeleton {
	width: 100px !important;
	height: 10px !important;
}

.sentence-skeleton {
	height: 10px !important;
}

.tab-skeleton {
	width: 50px !important;
	height: 10px !important;
}

.time-skeleton {
	width: 80px !important;
	height: 10px !important;
}

.brand-card-image-skeleton, .product-card-image-skeleton, .user-card-image-skeleton, .group-card-image-skeleton {
	width: 148px !important;
	height: 140px !important;
}

.brand-follow-card-image-skeleton {
	width: 126px !important;
	height: 126px !important;
}

.profile-image-skeleton {
	width: 40px !important;
	height: 40px !important;
}

.button-skeleton {
	width: 115px !important;
	height: 38px !important;
}

.ignite-button-skeleton {
	width: 130px !important;
	height: 34px !important;
}

.brand-follow-button-skeleton {
	width: 80px !important;
	height: 30px !important;
}

.brand-header-follow-button-skeleton {
	width: 115px !important;
	height: 34px !important;
}

.brand-follow-close-button-skeleton {
	width: 30px !important;
	height: 30px !important;
}

.post-image-skeleton {
	height: 380px !important;
}

.post-action-skeleton {
	width: 20px !important;
}

.post-action-button-skeleton {
	width: 60px !important;
	height: 15px !important;
}

.comment-compose-skeleton {
	height: 24px !important;
}

.comment-compose-button-skeleton {
	width: 24px !important;
	height: 24px !important;
}

.verify-skeleton {
	width: 17px !important;
	height: 17px !important;
}

.review-compare-button-skeleton {
	width: 60px !important;
	height: 25px !important;
}

.kebab-icon-skeleton {
	width: 6px !important;
	height: 16px !important;
}

.feature-card-image-skeleton {
	width: 267px !important;
	height: 291px !important;
}

.feature-card-brand-name-skeleton {
	height: 39px !important;
}

.brand-confidence-number-skeleton {
	width: 200px !important;
	height: 84px !important;
}

.brand-confidence-button-skeleton {
	height: 38px !important;
}

.brand-confidence-result-skeleton {
	width: 80px !important;
	height: 18px !important;
}

.sub-industry-skeleton {
	width: 80px !important;
	height: 30px !important;
}

.verify-skeleton {
	width: 13px !important;
	height: 13px !important;
}

.brand-profile-review-image-skeleton {
	width: 90px !important;
	height: 90px !important;
}

.brand-profile-review-text-skeleton {
	width: 60px !important;
	height: 20px !important;
}

.delete-btn-skeleton {
	width: 20px !important;
	height: 20px !important;
}
.switch-btn-skeleton {
	width: 30px !important;
	height: 20px !important;
}

.brand-profile-review-count-skeleton {
	width: 40px !important;
	height: 10px !important;
}

.brand-review-vote-skeleton {
	width: 10px !important;
	height: 13px !important;
}

.own-brand-image-skeleton {
    width: 40px !important;
    height: 40px !important;
}

.product-name-skeleton {
	width: 215px !important;
	height: 30px !important;
}

.product-review-type-skeleton {
	width: 215px !important;
	height: 30px !important;
}
.product-image-skeleton {
    width: 345px !important;
    height: 345px !important;
}

.product-preview-image-skeleton {
    width: 45px !important;
    height: 45px !important;
}

.product-review-number-skeleton {
	width: 150px !important;
	height: 83px !important;
}

.brand-follow-image-skeleton, .wishlist-image-skeleton {
    width: 52px !important;
    height: 48px !important;
}

.user-followers-brand-follow-button-skeleton {
	width: 90px !important;
    height: 34px !important;
}

.recommend-modal-image-skeleton {
    width: 2.5em !important;
    height: 2.5em !important;
}

.recommend-button-skeleton {
	width: 133px !important;
	height: 38px !important;
}

.product-details-button-skeleton {
	width: 115px !important;
	height: 34px !important;
}

.benefit-text-skeleton {
	height: 13px !important;
}

.update-image-skeleton {
	width: 75px !important;
	height: 71px !important;
}

.poll-title-skeleton {
	width: 100px !important;
	height: 21px !important;
}

.poll-radio-skeleton {
	height: 28px !important;
}

.poll-vote-button-skeleton {
	width: 132px !important;
	height: 38px !important;
}

.product-review-tab-title-skeleton {
    width: 72px !important;
    height: 20px !important;
}

.brand-review-tab-title-skeleton {
    width: 57px !important;
    height: 20px !important;
}

.influene-follower-tab-title-skeleton {
    width: 85px !important;
    height: 20px !important;
}

.influene-following-tab-title-skeleton {
    width: 89px !important;
    height: 20px !important;
}

.influene-tab-badge-skeleton {
    width: 22px !important;
    height: 20px !important;
}

.influene-brand-tab-title-skeleton, .influene-wishlist-tab-title-skeleton {
    width: 143px !important;
    height: 20px !important;
}