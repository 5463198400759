.width-comment-box{
    @media screen and (max-width:767px) {
        width: 100% !important;
    }

    @media screen and (min-width:768px) {
        width: 50% !important;
    }
}

.user-details {
    bottom: -25px !important;
    .user-profile-photo {
        @media screen and (max-width: 575px) {
            width: 105px !important;
            height: 105px !important;
            box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
        }
    }
}

.user-tab {
    @media screen and (min-width: 767px) {
        flex-wrap: nowrap !important;
        overflow-x: hidden !important;

    }
}

// Brand profile
.brand-profile-photo {
    @media screen and (max-width: 575px) {
        width: 60px !important;
        height: 60px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }

    @media screen and (min-width: 576px) {
        width: 135px !important;
        height: 135px !important;
        box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.13), 0 0 7px rgba(0, 0, 0, 0.25) !important;
    }
}

.brand-tab {
    @media screen and (min-width: 767px) {
        flex-wrap: nowrap !important;
        overflow-x: hidden !important;

    }
    @media screen and (max-width: 575px) {
        margin-left: 7rem !important;

    }
}

.brand-cover-photo {
    @media screen and (max-width: 684px) {
        height: 200px !important;
    }

    @media screen and (min-width: 685px) {
        height: 245px !important;
    }
}

.brand-profile-review-image {
    width: 100px !important;
    height: 100px !important;
    aspect-ratio: auto 100 / 100 !important;
}

/* ---------------------------------------------------
    SKELETON STYLE
----------------------------------------------------- */
.profile-image-skeleton {
    @media only screen and (max-width: 991px) {
        width: 35px !important;
        height: 35px !important;
    }
}

.user-profile-photo-skeleton {
    @media screen and (max-width: 575px) {
        width: 105px !important;
        height: 105px !important;
    }

    @media screen and (min-width: 576px) {
        width: 135px !important;
        height: 135px !important;
    }
}

.brand-profile-photo-skeleton {
    @media screen and (max-width: 575px) {
        width: 105px !important;
        height: 101px !important;
    }

    @media screen and (min-width: 576px) {
        width: 135px !important;
        height: 131px !important;
    }
}

.brand-confidence-result-skeleton {
    @media screen and (max-width: 575px) {
        width: 65px !important;
        height: 36px !important;
    }
}

// ------footer---------
.site-logo {
    @media screen and (min-width: 768px) and (max-width: 991px) {
        width: 140px !important;
    }
    @media screen and (max-width: 550px) {
        width: 150px !important;
    }
    @media screen and (max-width: 355px) {
        width: 105px !important;
    }
    @media screen and (min-width: 356px) and (max-width: 425px) {
        width: 120px !important;
    }
}

// brand suggestion
.brand-preview-image {
    @media (max-width: 575px) {
        width: 200px !important;
    }
}
.w-sm-25 {
    @media screen and (min-width: 576px) {
        width: 25% !important;
    }
}
// half of width
.w-sm-50 {
    @media screen and (min-width: 576px) {
        width: 50% !important;
    }
}

// date range picker
.react-datepicker {
    @media screen and (max-width: 767px) {
        display: inline-flex !important;
        flex-direction: column !important;
        overflow: scroll !important;
        height: 100vh !important;
        padding-bottom: 3rem !important;
    }
}